<template>
  <div class="vg_wrapper">
    <publicTips></publicTips>
    <div class="vd_search_group">
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getQrcdsNow()">
        <el-row>
          <el-col :md="7">
            <el-form-item label="物料编号:">
              <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="7">
            <el-form-item label="潘通色号:">
              <el-input size="small" v-model="searchForm.mtrl_color" clearable placeholder="请填写物料编号名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="10">
            <el-form-item label="库位：">
              <el-select filterable size="small" v-model="searchForm.qrcd_bin" placeholder="请选择库位" clearable>
                <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.id"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getQrcdsNow()" class="vg_ml_16">查询</el-button>
              <el-button size="small" type="info" icon="el-icon-refresh-right" @click="refesh()" class="vg_ml_16">刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="vd_button_group vg_mtb_16">
      <el-row class="vd_mar15">
        <el-button size="mini" type="danger" @click="cancel">取消</el-button>
        <el-button size="mini" type="primary" @click="confirmIn()">恢复</el-button>
      </el-row>
    </div>
    <el-row>
      <el-col :md="24">
        <el-table ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" border v-loading="loading">
          <el-table-column type="selection" width="48" align="center" />
          <el-table-column label="物料编号" prop="mtrl_no" />
          <el-table-column label="二维码图片" align="center">
            <template slot-scope="scope">
              <el-image class="vd_elimg" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
            </template>
          </el-table-column>
          <el-table-column label="物料名称" prop="mtrl_name" />
          <el-table-column label="物料类型" prop="mtrl_type" :formatter="formatLeavType" />
          <el-table-column label="潘通色号" prop="mtrl_color" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_color">
                <div class="vd_dis">
                  <div :class="{ vd_div: scope.row.colr_html }" :style="{ 'background-color': scope.row.colr_html }"></div>
                  <span>{{ scope.row.mtrl_color }}</span>
                </div>
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="门幅" prop="mtrl_width">
            <template slot-scope="scope">
              <div v-if="scope.row.mtrl_width">
                {{ scope.row.mtrl_width | formatMtrlWidth }}
              </div>
              <div v-else class="vg_9f9a9a">暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="物料毛高" prop="mtrl_thick">
            <template slot-scope="scope">
              <span v-if="scope.row.mtrl_thick">
                {{ scope.row.mtrl_thick | formaUnitH }}
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="库位" prop="qrcd_bin_no" />
          <el-table-column label="数量" prop="qrcd_num" :formatter="formatLeavNum" />
          <el-table-column label="删除时间" prop="delete_time" :formatter="formatDate" />
          <!-- <el-table-column label="单据状态" :show-overflow-tooltip='true' align="center">
            <template slot-scope="scope">
              <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
            </template>
          </el-table-column> -->
        </el-table>
      </el-col>
    </el-row>
    <el-row>
      <el-col :md="24">
        <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { qrcdAPI } from '@api/modules/qrcd';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';

export default {
  name: 'QrcdList',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrl_no: null,
        qrcd_bin: null,
        mtrl_color: null
      },
      totalPage: 0,
      btn: {},
      loading: true,
      multiSelection: [],
      currentPage: 1,
      binList: [],
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      dialogVisible: false,
      qrcdPrint: []
    };
  },
  created() {
    this.initData();
  },
  filters: {
    formatMtrlWidth(row) {
      return helper.haveFour(row) + '米';
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.getQrcdsList();
      this.getBinList();
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 })
        .then(res => {
          if (res.data.code === 0) {
            this.binList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取二维码信息
    getQrcdsList() {
      get(qrcdAPI.getQrcdsByRecycleBin, {
        mtrl_no: this.searchForm.mtrl_no,
        qrcd_bin: this.searchForm.qrcd_bin,
        mtrl_color: this.searchForm.mtrl_color,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 取消选择
    cancel() {
      this.$emit('confirmIn', false);
    },
    // 确认按钮
    confirmIn() {
      this.doDelete();
    },
    // 查询方法
    getQrcdsNow() {
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getQrcdsList();
    },
    //刷新
    refesh() {
      (this.searchForm = {
        mtrl_no: null,
        qrcd_bin: null,
        mtrl_color: null
      }),
        (this.$refs.pubPagination.currentPage = 1);
      this.currentPage = 1;
      this.getQrcdsList();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getQrcdsList();
    },
    // 图片信息
    formatPic(scope, type) {
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      return this.helper.picUrl(scope.row.qrcd_url, type, updateTime);
    },
    // 物料类型
    formatLeavType(row) {
      if (row.mtrl_type === 0) {
        return '原面料';
      } else if (row.mtrl_type === 1) {
        return '辅料';
      } else if (row.mtrl_type === 2) {
        return '包材';
      } else if (row.mtrl_type === 3) {
        return '加工面料';
      }
    },
    // 转换单位
    formatLeavNum(row) {
      return row.qrcd_num + row.mtrl_unit;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.delete_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        this.mBox();
      }
    },
    //物料信息list删除提示
    mBox() {
      this.$confirm('确定恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.qrcd_id);
          });
          post(qrcdAPI.cancelDelete, { qrcd_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                this.$emit('confirmIn', false);
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis {
  display: flex;
}
</style>
