<template>
  <div class="vg_wrapper">
    <div class="vd_over" id="printTest" style="display: flex; flex-direction: column; align-items: center; height: 290px; overflow-y: auto">
      <div
        v-for="item in qrcdPrint"
        :key="item.qrcd_id"
        style="width: 160px; height: 287px; font-size: 16px; padding-left: 10px; padding-top: 5px"
      >
        <div style="display: flex; justify-content: center; width: 100%">
          <img style="width: 120px; height: 120px; margin-bottom: 5px" :src="helper.picUrlNo(item.qrcd_url)" />
        </div>
        <!--  高度限死，打印机仅支持80MM ，预览打印内容显示最后两个所选值 -->
        <div style="height: 162px">
          <div style="margin-bottom: 1px; width: 160px">
            <span style="font-size: 12px">物料编号：</span><span style="font-weight: 700;font-size: 12px">{{ item.mtrl_no }}</span>
          </div>
          <div style="width: 160px; margin-bottom: 1px">
            <span style="font-size: 12px">物料名称：</span><span style="font-weight: 700;font-size: 12px">{{ item.mtrl_name }}</span>
          </div>
          <div style="margin-bottom: 1px" v-if="item.mtrl_type === 0">
            <span style="font-size: 12px">潘通色号：</span><span style="font-weight: 700;font-size: 12px">{{ item.mtrl_color }}</span>
          </div>
          <div style="margin-bottom: 1px" v-else>
            <span style="font-size: 12px">物料规格：</span><span style="font-weight: 700;font-size: 12px" v-if="item.mtrl_spec">{{ item.mtrl_spec }}</span
            ><span v-else> 暂无 </span>
          </div>
          <!-- <div style="margin-bottom:1px;">
            <span style="font-size:14px;">供应商：</span><span style="font-weight:700;" v-if="item.supp_name">{{item.supp_name}}</span><span v-else> 暂无 </span>
          </div>
          <div style="margin-bottom:1px;">
            <span style="font-size:14px;">物料门幅：</span><span style="font-weight:700;" v-if="item.mtrl_width">{{item.mtrl_width}}</span><span v-else> 暂无 </span>
          </div> -->
          <template v-if="item.mtrl_type === 0">
            <div style="margin-bottom: 1px">
              <span style="font-size: 12px">克重：</span><span style="font-weight: 700;font-size: 12px">{{ item.mtrl_weight }}克</span>
            </div>
            <div style="margin-bottom: 1px">
              <span style="font-size: 12px">毛高：</span><span style="font-weight: 700;font-size: 12px">{{ item.mtrl_thick }}毫米</span>
            </div>
          </template>
          <div>
            <span style="font-size: 12px">库位：</span><span style="font-weight: 700;font-size: 12px">{{ item.qrcd_bin_no }}</span>
          </div>
          <div>
            <span style="font-size: 12px">门幅：</span><span style="font-weight: 700;font-size: 12px">{{ item.mtrl_width }}</span>
          </div>
          <div>
            <span style="font-size: 12px">供应商：</span><span style="font-weight: 700;font-size: 12px">{{ item.supp_name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="vd_foot">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="print">打印</el-button>
    </div>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import { getLodop } from '../../../../../plugins/LodopFuncs';

export default {
  name: 'SmplEditBom',
  components: {},
  props: {
    qrcdPrint: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    initData() {},
    //
    cancel() {
      this.$emit('printCancel');
    },
    // 打印
    print() {
      const LODOP = getLodop();
      const strap = '<body>' + document.getElementById('printTest').innerHTML + '</body>';
      // LODOP.PRINT_INIT('')
      // LODOP.SET_PRINT_PAGESIZE(1,'50mm',80*this.multiSelection.length+'mm',"")
      // LODOP.SET_PRINT_PAGESIZE(1,'50mm','80mm','')
      // LODOP.SET_PRINT_STYLE('Alignment','2')
      LODOP.ADD_PRINT_HTM(10, 5, 400, 400 * this.qrcdPrint.length, strap);
      // for(let i=0;i<this.multiSelection.length;i++){
      //   let temp = this.multiSelection[i].qrcd_url
      //   LODOP.SET_PRINT_STYLE("FontSize",12)
      //   LODOP.ADD_PRINT_HTM(10,5,400,400,strap)
      //   // LODOP.ADD_PRINT_IMAGE(80*(i)+0+'mm','5mm','40mm','40mm',"<img width='140px'height='140px' src='http://192.168.1.38:8885/file"+temp+".jpg'/>")
      //   // LODOP.ADD_PRINT_TEXT(80*(i)+40+'mm','0mm','50mm','10mm',"物料编号："+this.multiSelection[i].mtrl_no)
      //   // LODOP.ADD_PRINT_TEXT(80*(i)+48+'mm','0mm','50mm','10mm',"物料名称："+this.multiSelection[i].mtrl_name)
      //   // LODOP.ADD_PRINT_TEXT(80*(i)+56+'mm','0mm','50mm','10mm',"库位名称："+this.multiSelection[i].qrcd_bin_no)
      // }
      // LODOP.SET_PRINT_COPIES(this.multiSelection.length)
      LODOP.PREVIEW();
      setTimeout(() => {
        this.cancel();
      }, 2000);
    }
  }
};
</script>

<style scoped lang="scss">
.vd_over {
  height: 500px;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}
.vd_elimg {
  width: 30px;
  height: 30px;
}
.vd_mrt {
  margin-top: 20px;
}
.vd_mar15 {
  margin: 15px 0;
}
.vd_ergodic {
  width: 140px;
  height: 140px;
  padding: 10px 5px 0;
  font-size: 6px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_over::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.vd_over::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.vd_over::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(245, 218, 10);
}
.vd_foot {
  margin-top: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
