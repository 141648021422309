import topic from '../topic';
export const qrcdAPI = {
  getQrcds: topic.qrcdTopic + '/get_qrcds',
  addQrcd: topic.qrcdTopic + '/add_qrcd',
  deleteQrcdByIds: topic.qrcdTopic + '/delete_qrcd_by_ids',
  getQrcdById: topic.qrcdTopic + '/get_qrcd_by_id',
  getQrcdsByRecycleBin: topic.qrcdTopic + '/get_qrcds_by_recycle_bin',
  cancelDelete: topic.qrcdTopic + '/cancel_delete',
  addMtrlToQrcd: topic.qrcdTopic + '/add_mtrl_to_qrcd'
};
